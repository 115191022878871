import { api } from '../../../../utils';

export const Step = (store) => {
  return [
    {
      name: '店铺授权',
      toText: '去授权>',
      toUrl: () => store.toPage('/egenie-ts-vogue/shopAuthorization/index', '755', '店铺授权', 'in'),
      img: `${api.oss}/images/toAuthorization.png`,
    },
    {
      name: '衫海精首页选款',
      toText: '去选款>',
      toUrl: () => store.toPage('/egenie-ts-vogue/homePage/index', 'homePage', '衫海精-选款履约一站式服务平台', 'out'),
      img: `${api.oss}/images/toXk.png`,
    },
    {
      name: '代发下单',
      toText: '我的订单>',
      toUrl: () => store.toPage('/egenie-ts-vogue/myOrder/index', '753', '我的订单', 'in'),
      img: `${api.oss}/images/toMyOrder.png`,
    },
    {
      name: '无忧售后',
      toText: '售后管理>',
      toUrl: () => store.toPage('/egenie-ts-vogue/afterSaleOrder/index', '754', '售后管理', 'in'),
      img: `${api.oss}/images/toAfterSale.png`,
    },
  ];
};
