import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { nanoid } from 'nanoid';
import React from 'react';
import { Step } from './constant';
import styles from './index.less';

export class NoviceGuidance extends React.Component {
  public state = {
    visible: false,
    isHover: false,
  };

  componentDidMount(): void {
    this.setState({ visible: !localStorage.getItem('xkDialogNotice') });
    localStorage.setItem('xkDialogNotice', 'true');
  }

  /**
   * 跳转页面
   * @param url 跳转地址
   * @param pageId 页面id
   * @param name 页面名称
   * @param type 内部跳转、外部跳转
   */
  public toPage = (url, pageId, name, type) => {
    type === 'in' ? top.egenie.openTab(url, pageId, name) : window.open(url);
    this.setState({ visible: false });
  };

  render() {
    const { visible, isHover } = this.state;
    return (
      <>
        <Modal
          className={styles.noviceGuidance}
          footer={[
            <Button
              key={nanoid()}
              onClick={() => this.setState({ visible: false })}
              type="primary"
            >
              我已了解
            </Button>,
          ]}
          maskClosable={false}
          onCancel={() => this.setState({ visible: false })}
          open={visible}
          title="新手引导"
          width={800}
        >
          <p className={styles.tip}>
            <ExclamationCircleOutlined className={styles.tipIcon}/>
            按照以下流程操作，即可完成订单一件代发。
            <span
              className={styles.toDetail}
              onClick={() => window.open('https://www.yuque.com/egenie/cpoc39/zgs5gu')}
            >
              查看详细操作说明
            </span>
          </p>
          <div className={styles.stepContainer}>
            {
              Step(this).map((item, index) => (
                <div
                  className={styles.singleStep}
                  key={nanoid()}
                >
                  <p className={styles.stepTitle}>
                    <span className={styles.stepNum}>
                      {index + 1}
                      .
                    </span>
                    <div className={styles.stepNameContainer}>
                      <span className={styles.stepName}>
                        {item.name}
                      </span>
                      {
                        index < 3 && <span className={styles.line}/>
                      }
                    </div>
                  </p>
                  <p
                    className={styles.toText}
                    onClick={() => item.toUrl()}
                  >
                    {item.toText}
                  </p>
                  <img
                    alt=""
                    className={styles.stepImg}
                    src={item.img}
                  />
                </div>
              ))
            }
          </div>
        </Modal>
        <div
          className={styles.question}
          onClick={() => this.setState({ visible: true })}
          onMouseEnter={() => this.setState({ isHover: true })}
          onMouseLeave={() => this.setState({ isHover: false })}
        >
          {
            !isHover ? <QuestionCircleOutlined className={styles.questionIcon}/> : (
              <span className={styles.questionText}>
                帮助文档
              </span>
            )
          }
        </div>
      </>
    );
  }
}
