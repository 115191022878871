import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import React from 'react';
import styles from './index.less';

export class SalesOverview extends React.Component {
  public state = {
    saleNum: 0,
    saleMoney: 0,
  };

  componentDidMount(): void {
    this.queryTopSale();
  }

  public queryTopSale = async(): Promise<void> => {
    const req = await request<BaseData<any>>({
      url: '/api/bi/erp_current/orders/indexTimeline/6/7',
      method: 'get',
    });

    this.setState({
      saleNum: req.data.data.data.reduce((prev, next) => {
        return prev + next;
      }),
      saleMoney: req.data.numData.data.reduce((prev, next) => {
        return Number(prev) + Number(next);
      }),
    });
  };

  public formatNumber(str) {
    if (isNaN(str) || !str) {
      return null;
    }

    let s = (`${str }`).replace(/(^0+)|(\.+$)|(0+$)/g, '');

    const regExp = new RegExp('^(-?[0-9]+)([0-9]{3})');
    while (regExp.test(s)) {
      s = s.replace(regExp, '$1,$2');
    }

    return s;
  }

  render() {
    const { saleNum, saleMoney } = this.state;
    return (
      <div className={styles.salesOverview}>
        <p className={styles.salesOverviewTitle}>
          近7日店铺销售概览
        </p>
        <div className={styles.infoContainer}>
          {/* 销量 */}
          <div className={styles.info}>
            <p className={styles.infoTitle}>
              销售（单）
            </p>
            <p className={styles.infoText}>
              {this.formatNumber(saleNum)}
            </p>
          </div>
          {/* 销售额 */}
          <div className={styles.info}>
            <p className={styles.infoTitle}>
              销售额（元）
            </p>
            <p className={styles.infoText}>
              ￥
              {this.formatNumber(Number(saleMoney.toFixed(2)))}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
