import React from 'react';
import comingSoon from '../../../../assets/images/comingSoon.png';
import styles from './index.less';

export class NewStall extends React.Component {
  render() {
    return (
      <div className={styles.newStall}>
        <p className={styles.newStallTitle}>
          今日档口上新
        </p>
        <div className={styles.infoContainer}>
          <img
            alt=""
            className={styles.tipImg}
            src={comingSoon}
          />
          <p className={styles.tip}>
            功能开发中...
          </p>
        </div>
      </div>
    );
  }
}
