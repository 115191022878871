import { Input, Button, Form, message } from 'antd';
import { request, phoneReg } from 'egenie-common';
import React from 'react';
import { api } from '../../../../utils';
import styles from './index.less';

message.config({ top: 56 });

export class ContactService extends React.Component {
  public form = null;

  public handleSubmit = async(values): Promise<void> => {
    const { feedBackphone, feedBacktext } = values;
    await request({
      method: 'post',
      url: api.commitFeedback,
      data: {
        content: feedBacktext,
        mobile: feedBackphone,
      },
    });
    message.success('反馈成功');
    this.form && this.form.setFieldsValue({
      feedBackphone: '',
      feedBacktext: '',
    });
  };

  render(): JSX.Element {
    return (
      <div className={styles.contactService}>
        {/* 联系客服 */}
        <div className={styles.contact}>
          <p className={styles.title}>
            联系客服
          </p>
          <div className={styles.qrCodeContainer}>
            <p className={styles.tip}>
              有任何疑问
            </p>
            <p className={styles.tip}>
              请微信扫码联系客服
            </p>
            <img
              alt=""
              className={styles.qrCodeImg}
              src={`${api.oss}/images/xkQrCode.png`}
            />
            <p className={styles.tip}>
              衫海精·福利助手优优
            </p>
          </div>
        </div>
        {/* 反馈、电话 */}
        <div className={styles.formContainer}>
          <Form
            className={styles.feedBack}
            onFinish={this.handleSubmit}
            ref={(form) => {
              this.form = form;
            }}
            requiredMark={false}
          >
            <Form.Item
              className={styles.feedBackQuestion}
              label="建议反馈"
              labelCol={{ span: 24 }}
              name="feedBacktext"
              rules={[
                {
                  required: true,
                  message: '请输入您的问题',
                },
              ]}
            >
              <Input.TextArea
                className={styles.text}
                placeholder="请输入您的问题"
              />
            </Form.Item>
            <Form.Item
              className={styles.feedBackPhoneInput}
              label="联系电话"
              labelCol={{ span: 24 }}
              name="feedBackphone"
              rules={[
                {
                  required: true,
                  message: '请输入手机号',
                },
                {
                  pattern: phoneReg,
                  message: '请输入正确手机号',
                },
              ]}
            >
              <Input
                maxLength={11}
                placeholder="请输入11位有效手机号"
                type="phone"
              />
            </Form.Item>
            <Form.Item>
              <Button
                className={styles.submit}
                htmlType="submit"
                type="primary"
              >
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
