import { action, observable, computed } from 'mobx';
import type { ModalProps } from 'antd';
import { Button } from 'antd';
import styles from './index.less';
import React from 'react';

export default class AlarmModel {
  @observable public open = false;

  @observable public hours: number;

  @observable public totalPrice: number;

  @computed public get zIndex(): number {
    return window.top.egenie.activeTabKey ? -1 : 10;
  }

  @action
  public onCancel = () => {
    this.open = false;
    this.hours = undefined;
  };

  @action
  public onOpen = (hours: number, totalPrice: number) => {
    if (!hours) {
      return;
    }
    this.hours = hours;
    this.totalPrice = totalPrice || 0.00;
    this.open = true;
  };

  public goto = () => {
    this.onCancel();
    window.top?.egenie?.openTab('/ejl-payment/payable', 2101, '应付单');
  };

  public gotoYiLian = () => {
    this.onCancel();
    window.top?.egenie?.openTab('/egenie-ts-payment/yilianIous/index', 60114, '依链白条');
  };

  public get getModalProps(): ModalProps {
    return {
      centered: true,
      width: 500,
      title: [
        72,
        432,
      ].includes(this.hours) ? '停发提醒(依链网仓)' : '欠费提醒(依链网仓)',
      open: this.open,
      onCancel: this.onCancel,
      footer: (
        <div className={styles.footer}>
          <Button
            onClick={this.goto}
            type="primary"
          >
            去付款
          </Button>
        </div>
      ),
    };
  }
}
