import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Radio, Space } from 'antd';
import { api } from '../../../../utils/index';
import styles from './index.less';
import type Store from './store';

export default observer((props: { store: Store; }) => {
  const { visible, close, onOk, onRadioChange, radioValue } = props.store;

  return (
    <Modal
      centered
      maskClosable={false}
      okText="去授权"
      onCancel={close}
      onOk={onOk}
      open={visible}
      title="选择授权应用"
      width={600}
      zIndex={1001}
    >
      <div className={styles.applicationModal}>
        <Radio.Group
          onChange={(e) => onRadioChange(e.target.value)}
          value={radioValue}
        >
          <Space size={24}>
            <label className={styles.radioWrapper}>
              <Radio
                value="new"
              >
                新版应用
              </Radio>
              <img
                onDragStart={(e) => e.preventDefault()}
                src={`${api.oss}/images/newApplication.png`}
              />
            </label>
            <label className={styles.radioWrapper}>
              <Radio
                value="old"
              >
                旧版应用
              </Radio>
              <img
                onDragStart={(e) => e.preventDefault()}
                src={`${api.oss}/images/oldApplication.png`}
              />
            </label>
          </Space>
        </Radio.Group>
      </div>
    </Modal>
  );
});
