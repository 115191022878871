
import { observer, Provider } from 'mobx-react';
import React, { useEffect } from 'react';
import { DialogNotice } from '../../components/index';
import { ContactService, Help, NewStall, Notice, NoviceGuidance, SaleChart, SalesOverview, TodoList } from '../components/index';
import styles from './index.less';
import dashboardStore from '../../dashboard/dashboardStore';
import { Button, Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

export class Dashboard extends React.Component {
  render(): JSX.Element {
    return (
      <div className={styles.dashboard}>
        <RenderWarnModal/>
        <Provider>
          <div className={styles.panel}>
            <SalesOverview/>
            <TodoList/>
          </div>
          <div className={styles.panel}>
            <SaleChart/>
            <NewStall/>
          </div>
          <div
            className={styles.panel}
            style={{ marginBottom: 0 }}
          >
            <Notice/>
            <Help/>
            <ContactService/>
          </div>
          <DialogNotice/>
          <NoviceGuidance/>
        </Provider>
      </div>
    );
  }
}

export const RenderWarnModal = observer(() => {
  const { showWarnModal, warnContent, getWarn, hiddenModal } = dashboardStore;

  useEffect(() => {
    getWarn();
  }, []);

  const jumpToPayPage = () => {
    hiddenModal();
    top.egenie.openTab('/egenie-ts-payment/iousBill/index', 'baitiaozhangdan', '白条账单');
  };

  return (
    <Modal
      centered
      footer={(
        <Button
          onClick={jumpToPayPage}
          type="primary"
        >
          立即还款
        </Button>
      )}
      maskClosable={false}
      onCancel={hiddenModal}
      open={showWarnModal}
      title={(
        <div className={styles.warnModalTitle}>
          <ExclamationCircleFilled className={styles.warnModalIcon}/>
          依链白条还款提醒
        </div>
      )}
      width={337}
    >
      <div dangerouslySetInnerHTML={{ __html: warnContent }}/>
    </Modal>
  );
});

export default observer(Dashboard);
