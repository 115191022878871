import { request } from 'egenie-common';
import { action, computed, observable } from 'mobx';
import { api } from '../../../utils';
import type { Response, ShopExpire } from './interface';
import { message, Modal } from 'antd';
import AuthModalStore from './authModal/store';

const PLATFORM_TYPE_CODE = {
  '淘宝(天猫)': 1,
  京东: 2,
};

export default class DialogNoticeStore {
  @observable public isShowShopExpire = false;

  @observable public shopExpireList: ShopExpire[] = [];

  public authModalStore = new AuthModalStore();

  @computed public get zIndex(): number {
    return window.top.egenie.activeTabKey ? -1 : 10;
  }

  // 店铺过期
  public getShopExpireList = action(() => {
    request({ url: api.shopExpireList }).then((res: Response) => {
      if (!res.data) {
        this.toggleShopExpire(false);
        return;
      }
      this.shopExpireList = res.data;
      this.toggleShopExpire(true);
    });
  });

  public handleShopAgain = action((shopId: number, platformType: string) => {
    if ([
      '淘宝(天猫)',
      '京东',
    ].includes(platformType)) {
      this.authModalStore.open(PLATFORM_TYPE_CODE[platformType], shopId);
    } else {
      request({ url: `${api.shopAgain}/${shopId}` }).then((res: Response) => {
        window.open(res.data);
        this.toggleShopExpire(false);
      });
    }
  });

  public toggleShopExpire = action((isShow: boolean) => {
    this.isShowShopExpire = isShow;
  });

  // 处理启用和禁用
  public handleAbleShop = (id): void => {
    Modal.confirm({
      centered: true,
      title: '提醒',
      content: '确定要禁用吗？',
      onOk: () => {
        request({
          url: '/api/baseinfo/rest/shop/batchDisableShop',
          method: 'post',
          data: [id],
        }).then((res: Response) => {
          this.getShopExpireList();
          message.success('禁用成功');
        });
      },
    });
  };

  public onClickServiceSubscriptionRebatesButton = () => {
    window?.top?.egenie?.openTab('/egenie-ts-baseinfo/serviceSubscriptionRebates/index', 1800, '服务订购返款');
  };
}
