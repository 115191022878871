import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import React from 'react';
import styles from './index.less';

export class TodoList extends React.Component {
  public state = {
    tobeIssued: 0,
    tobePaidNum: 0,
    tobeShippedNum: 0,
    tobeReturn: 0,
  };

  componentDidMount(): void {
    this.queryPaidAndShipped();
    this.queryReturn();
    this.queryIssued();
  }

  // 获取待代发
  public queryIssued = async(): Promise<void> => {
    const req = await request<BaseData<any>>({
      url: '/api/saleorder/rest/order/smallCustomer/statistics',
      method: 'get',
    });
    this.setState({ tobeIssued: req.data.waitProxySendCount });
  };

  // 获取待付款和代发货
  public queryPaidAndShipped = async(): Promise<void> => {
    const req = await request<BaseData<any>>({
      url: '/api/mall/rest/saleOrder/querySaleOrderNum',
      method: 'get',
    });
    this.setState({
      tobePaidNum: req.data.filter((item) => item.orderStatus === 0)[0]?.number,
      tobeShippedNum: req.data.filter((item) => item.orderStatus === 1)[0]?.number,
    });
  };

  // 获取待退货
  public queryReturn = async(): Promise<void> => {
    const req = await request<BaseData<any>>({
      url: '/api/mall/rest/return/order/num',
      method: 'get',
    });
    this.setState({ tobeReturn: req.data });
  };

  public formatNumber(str) {
    if (isNaN(str) || !str) {
      return null || 0;
    }

    let s = (`${str }`).replace(/(^0+)|(\.+$)|(0+$)/g, '');

    const regExp = new RegExp('^(-?[0-9]+)([0-9]{3})');
    while (regExp.test(s)) {
      s = s.replace(regExp, '$1,$2');
    }
    return s;
  }

  render() {
    const { tobeIssued, tobePaidNum, tobeShippedNum, tobeReturn } = this.state;
    return (
      <div className={styles.todoList}>
        <p className={styles.todoListTitle}>
          待办事项
        </p>
        <div className={styles.infoContainer}>
          {/* 待代发(单) */}
          <div
            className={styles.info}
            onClick={() => {
              top.egenie.openTab('/egenie-ts-vogue/shopOrder/index?proxySendStatus=1', '752', '店铺订单');
            }}
          >
            <p className={styles.infoTitle}>
              未代发(单)
              {' '}
              {'>'}
            </p>
            <p className={styles.infoText}>
              {this.formatNumber(tobeIssued)}
            </p>
          </div>
          {/* 待付款(单) */}
          <div
            className={`${styles.info} ${styles.tobePaidInfo}`}
            onClick={() => {
              top.egenie.openTab('/egenie-ts-vogue/myOrder/index?orderStatus=0', '753', '我的订单');
            }}
          >
            <p className={styles.infoTitle}>
              待付款(单)
              {' '}
              {' '}
              {'>'}
            </p>
            <p className={styles.infoText}>
              {this.formatNumber(tobePaidNum)}
            </p>
          </div>
          {/* 待发货(单) */}
          <div
            className={`${styles.info} ${styles.tobeShippedInfo}`}
            onClick={() => {
              top.egenie.openTab('/egenie-ts-vogue/myOrder/index?orderStatus=1', '753', '我的订单');
            }}
          >
            <p className={styles.infoTitle}>
              待发货(单)
              {' '}
              {' '}
              {'>'}
            </p>
            <p className={styles.infoText}>
              {this.formatNumber(tobeShippedNum)}
            </p>
          </div>
          {/* 待退货(单) */}
          <div
            className={`${styles.info} ${styles.noMarginRight}`}
            onClick={() => {
              top.egenie.openTab('/egenie-ts-vogue/afterSaleOrder/index?status=1  ', '754', '售后管理');
            }}
          >
            <p className={styles.infoTitle}>
              待退货(单)
              {' '}
              {' '}
              {'>'}
            </p>
            <p className={styles.infoText}>
              {this.formatNumber(tobeReturn)}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
