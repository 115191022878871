import { DoubleRightOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import React from 'react';
import styles from './index.less';

export class Help extends React.Component {
  public helpList = [
    {
      title: '绑定店铺 >',
      url: 'https://www.yuque.com/egenie/cpoc39/nge2n9',
    },
    {
      title: '一键铺货 >',
      url: 'https://www.yuque.com/egenie/cpoc39/ofhvae',
    },
    {
      title: '下载订单 >',
      url: 'https://www.yuque.com/egenie/cpoc39/ibop1g',
    },
    {
      title: '一件代发 >',
      url: 'https://www.yuque.com/egenie/cpoc39/bcya5q',
    },
  ];

  render() {
    return (
      <div className={styles.help}>
        <div className={styles.header}>
          <span className={styles.title}>
            帮助文档
          </span>
          <span
            className={styles.more}
            onClick={() => window.open('https://www.yuque.com/egenie/cpoc39')}
          >
            更多
            <DoubleRightOutlined/>
          </span>
        </div>
        {
          this.helpList.map((item) => (
            <p
              className={styles.helpDocument}
              key={nanoid()}
              onClick={() => window.open(item.url)}
            >
              {item.title}
            </p>
          ))
        }
      </div>
    );
  }
}
