import { Popover } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import { api, setLocationHref } from '../../utils';
import { LayoutGuide, LayoutStore } from '../../utils/layout';
import { RelatedProducts } from './components';
import Dashboard from './dashboard/index';
import styles from './dashboard/index.less';

export const SHELVE_URL = {
  '-100': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesNew/index?platformType=-100&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '1': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesNew/index?platformType=1&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '21': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesDouyin/index?platformType=21&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '17': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesPdd/index?platformType=17&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '31': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesWxVideo/index?platformType=31&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
};

const layoutStore = new LayoutStore({
  project: {
    name: '',
    value: 'egenie-ts-vogue',
  },
  homePageType: 3,
});
layoutStore.handleInit();

interface Operation {
  id: string | number;
  name: string;
  callback?: () => void;
}

// 导航 示例
@observer
export default class LayoutTest extends React.Component {
  componentDidMount(): void {
    this.parserParams();
    setLocationHref();
  }

  public userInfoLeft = (): JSX.Element => {
    return (
      <div>
        {!layoutStore.associatedShopId && (
          <>
            <span
              onClick={() => window.open('/egenie-ts-vogue/homePage/index')}
              style={{
                color: '#1978ff',
                cursor: 'pointer',
              }}
            >
              衫海精首页
            </span>
            <span style={{
              margin: '0 12px',
              color: '#e2e2e5',
            }}
            >
              |
            </span>
            <Popover
              content={<RelatedProducts/>}
              overlayClassName={styles.phoneAppPopover}
            >
              <span
                style={{ cursor: 'pointer' }}
              >
                手机版
              </span>
            </Popover>
            <span style={{
              margin: '0 12px',
              color: '#e2e2e5',
            }}
            >
              |
            </span>
          </>
        )}
      </div>
    );
  };

  public userInfoRight = (): Operation[] => {
    return [
      {
        id: 'export',
        name: '导入导出任务中心',
        callback: () => top.egenie.openTab('/egenie-ts-baseinfo/exportList/index', 'export_task_center', '导入导出任务中心', 'zc_pfs'),
      },
    ];
  };

  public getLogoImg = (): JSX.Element => {
    return (
      <img
        onDragStart={(e) => {
          e.preventDefault();
        }}
        src={`${api.oss}/images/xkLogo.png`}
        style={{ width: '100%' }}
      />
    );
  };

  // 获取页面参数
  private parserParams = () => {
    const params = location.href.split('?')[1];
    const search = new URLSearchParams(params);
    const jumpTo = search.get('jumpTo') || '';
    const currentPlatformType = search.get('currentPlatformType');
    const gmsGoodsId = search.get('gmsGoodsId');
    const mallSaleOrderId = search.get('mallSaleOrderId');
    const mallReturnOrderId = search.get('mallReturnOrderId');
    const openDetail = search.get('openDetail') || 'No';
    const shopId = search.get('shopId');
    const proxySendStatus = search.get('proxySendStatus');

    switch (jumpTo) {
      case 'shelvesNew': // 上架
        {
          const pageId = nanoid(5);
          this.jumpToOtherPage(SHELVE_URL[currentPlatformType](gmsGoodsId, shopId, pageId), pageId, '上架');
        }
        break;
      case 'myStyle':// 我的款式
        this.jumpToOtherPage('/egenie-ts-vogue/myStyle/index', '256', '我的款式');
        break;
      case 'shopManage':// 店铺授权管理
        this.jumpToOtherPage('/egenie-ts-vogue/shopAuthorization/index', '755', '店铺授权管理');
        break;
      case 'myOrder': // 衫海精订单
        this.jumpToOtherPage('/egenie-ts-vogue/myOrder/index', '753', '衫海精订单');
        break;
      case 'afterSaleOrder': // 售后订单
        this.jumpToOtherPage('/egenie-ts-vogue/afterSaleOrder/index', '754', '售后订单');
        break;
      case 'myOrderDetail': // 衫海经订单详情
        this.jumpToOtherPage(`/egenie-ts-vogue/myOrderDetail/index?mallSaleOrderId=${mallSaleOrderId}`, 'myOrderDetail', '订单详情');
        break;
      case 'afterSaleOrderDetail': // 售后订单详情
        this.jumpToOtherPage(`/egenie-ts-vogue/afterSaleOrderDetail/index?mallReturnOrderId=${mallReturnOrderId}`, 'afterSaleOrderDetail', '售后单详情');
        break;
      case 'favoriteStyles': // 衫海经订单
        this.jumpToOtherPage('/egenie-ts-vogue/favoriteStyles/index', '270', '收藏的款式');
        break;
      case 'shopOrder': {
        let url = '/egenie-ts-vogue/shopOrder/index';
        if (proxySendStatus) {
          url += '?proxySendStatus=1';
        }
        this.jumpToOtherPage(url, '752', '店铺订单');
      }
        break;
      case 'repayment':
        this.jumpToOtherPage('/egenie-ts-payment/iousBill/index', 'baitiaozhangdan', '白条账单');
        break;
    }
  };

  // 跳转到别的页面
  private jumpToOtherPage = (url: string, tabId: string, tabName: string, icon?: string) => {
    window.top.egenie.openTab(url, tabId, tabName);
  };

  render(): JSX.Element {
    return (
      <div>
        <LayoutGuide
          defaultDashboard={<Dashboard/>}
          haveDashboard={false}
          logoImg={this.getLogoImg()}
          store={layoutStore}
          userInfoLeft={this.userInfoLeft()}
          userInfoRight={this.userInfoRight()}
        />
      </div>
    );
  }
}
