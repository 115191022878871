import React from 'react';
import { api } from '../../../../utils';
import styles from './index.less';

export class RelatedProducts extends React.Component {
  render() {
    return (
      <div className={styles.productsList}>
        <div className={styles.product}>
          <span>
            衫海精APP
          </span>
          <img
            alt=""
            className={styles.productImg}
            src={`${api.oss}/images/xkApp.png`}
          />
          <span>
            扫码下载
          </span>
        </div>
        <div className={styles.product}>
          <span>
            衫海精小程序
          </span>
          <img
            alt=""
            className={styles.productImg}
            src={`${api.oss}/images/applet.png`}
          />
          <span>
            微信扫码添加
          </span>
        </div>
      </div>
    );
  }
}
