import type { ModalProps } from 'antd';
import { Button, Modal } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import React, { useState, useEffect } from 'react';
import NoticeLogo from '../../assets/images/noticeLogo.png';
import styles from './index.less';

const StrongReminder = () => {
  const [
    visible,
    setVisible,
  ] = useState(false);
  const [
    data,
    setDate,
  ] = useState<{ content: string; id: number; title: string; }>({
    content: '',
    id: null,
    title: '',
  });

  useEffect(() => {
    getData();
  }, []);
  const getData = async() => {
    const res = await request<BaseData<{ content: string; id: number; title: string; }>>({ url: '/api/boss/baseinfo/rest/notice/get/important/notice' });
    if (res.data) {
      setVisible(true);
      setDate(res.data);
    }
  };
  const paramsModal: ModalProps = {
    // title: '停服升级公告',
    closable: false,
    centered: true,
    zIndex: 99999,
    width: 1000,
    footer: [
      <Button
        key={1}
        onClick={(e) => {
          setVisible(false);
        }}
        size="large"
        type="primary"
      >
        知道了
      </Button>,
    ],
  };
  return (
    <Modal
      {...paramsModal}
      className={styles.strongReminder}
      open={visible}
    >
      <div className={styles.topTitle}>
        <div className={styles.noticeText}>
          公告
        </div>
        <div className={styles.noticeTextEn}>
          Announcements
        </div>
        <img
          className={styles.noticeLogo}
          src={NoticeLogo}
        />
      </div>
      <div className={styles.contentWrap}>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </div>
    </Modal>
  );
};

export default StrongReminder;
