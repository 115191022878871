import ReactECharts from 'echarts-for-react';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import React from 'react';
import { doubleLineOptions } from '../saleChart/baseOptions';
import styles from './index.less';

export class SaleChart extends React.Component {
  public state = {
    xAxisData: [],
    series: [],
  };

  componentDidMount(): void {
    this.queryTopSale();
  }

  public queryTopSale = async(): Promise<void> => {
    const req = await request<BaseData<any>>({
      url: '/api/bi/erp_current/orders/indexTimeline/6/7',
      method: 'get',
    });
    this.setState({
      xAxisData: req.data.xAxisData,
      series: [
        req.data.data.data,
        req.data.numData.data,
      ],
    });
  };

  render() {
    const { xAxisData, series } = this.state;

    const option = {
      ...doubleLineOptions,
      xAxis: {
        ...doubleLineOptions.xAxis,
        data: xAxisData,
      },
      series: [
        {
          ...doubleLineOptions.series[0],
          data: series[0],
        },
        {
          ...doubleLineOptions.series[1],
          data: series[1],
        },
      ],
    };

    return (
      <div className={styles.saleChart}>
        <p className={styles.title}>
          电商平台销售趋势
        </p>
        <div>
          <ReactECharts
            lazyUpdate
            notMerge
            option={option}
            theme="theme_name"
          />
        </div>
      </div>
    );
  }
}
