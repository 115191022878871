import { observable, action } from 'mobx';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';

const TAOBAO_APPKEY = {
  new: '33846839',
  old: '32317427',
};

const JINGDONG_APPKEY = {
  new: 'BA506D313E4C6525E52DC495EE1E3165',
  old: '3FF5BFFF8CE76DB164F9ED19CD25F388',
};

export default class AuthModalStore {
  @observable public radioValue = 'new';

  @observable public visible = false;

  @observable public platformType: number = null;

  @observable public shopId: number = null;

  @action
  public onRadioChange = (value: string) => {
    this.radioValue = value;
  };

  @action
  public open = (platformType: number, shopId: number) => {
    this.visible = true;
    this.platformType = platformType;
    this.shopId = shopId;
    if (platformType === 1) {
      this.radioValue = 'new';
    } else if (platformType === 2) {
      this.radioValue = 'old';
    }
  };

  @action
  public close = () => {
    this.visible = false;
    this.radioValue = 'new';
    this.platformType = null;
    this.shopId = null;
  };

  @action
  public onOk = async() => {
    let url = '';
    if (this.platformType === 1) {
      // 淘宝
      const { data } = await request<BaseData<string>>({
        method: 'GET',
        url: `/api/baseinfo/rest/shop/auth/taobao/${this.shopId}`,
        params: { appKey: TAOBAO_APPKEY[this.radioValue] },
      });
      url = data;
    } else if (this.platformType === 2) {
      const { data } = await request<BaseData<string>>({
        method: 'GET',
        url: `/api/baseinfo/rest/shop/auth/mutilkey/${this.shopId}`,
        params: { appKey: JINGDONG_APPKEY[this.radioValue] },
      });
      url = data;
    }
    this.close();
    window.open(url);
  };
}
