import { Dropdown, Tabs } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styles from './layoutMenu.less';
import type { LayoutStore } from './layoutStore';
import { PushpinOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { toJS } from 'mobx';

@inject('layoutStore')
@observer
export class HeaderTabs extends React.Component<{ layoutStore?: LayoutStore; }> {
  componentDidMount() {
    /**
     * 禁止home项目里面使用浏览器默认右键功能
     * @param ev
     */
    document.oncontextmenu = (ev: any) => {
      ev = ev || window.event;
      return false;
    };
  }

  render() {
    const {
      handleTabRemove,
      tabList,
      activeTabKey,
      isMenuExists,
      handleTabChange,
      onFixedClick,
    } = this.props.layoutStore;
    const items = tabList.map((item, index) => ({
      label: item.id === 0 ? (
        <span className={styles.tabContent}>
          <span className={`${styles.tabIcon} icon-${item.icon}`}/>
          <span className={styles.headerTabItem}>
            {item.name}
          </span>
        </span>
      ) : (
        <Dropdown
          menu={{
            items: [
              {
                disabled: !isMenuExists(item.id),
                label: (
                  <div className={styles.labelPage}>
                    <PushpinOutlined className={styles.labelPageIcon}/>
                    { item.isFixed ? '取消固定标签页' : '固定标签页'}
                  </div>),
                key: '1',
                onClick: () => {
                  onFixedClick(item.id, item.isFixed || 0);
                },
              },
              {
                label: (
                  <div
                    className={styles.labelPage}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleTabRemove(`${item.id}`);
                    }}
                  >
                    <CloseCircleOutlined className={styles.labelPageIcon}/>
                    关闭标签页
                  </div>
                ),
                key: '2',
                onClick: (e) => {
                  handleTabRemove(`${item.id}`);
                },
              },
            ],
          }}
          trigger={['contextMenu']}
        >
          <span className={styles.tabContent}>
            <span className={`${styles.tabIcon} icon-${item.icon}`}/>
            <span className={styles.headerTabItem}>
              {item.name}
            </span>
          </span>
        </Dropdown>
      ),
      key: String(item.id),
      closable: index !== 0,
    }));
    return (
      <Tabs
        activeKey={String(activeTabKey)}
        className={styles.headerTabs}
        hideAdd
        id={styles.headerTabs}
        items={items}
        onChange={handleTabChange}
        onEdit={handleTabRemove}
        tabBarGutter={0}
        type="editable-card"
      />
    );
  }
}
