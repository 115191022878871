import { DoubleRightOutlined } from '@ant-design/icons';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import React from 'react';
import styles from './index.less';

export class Notice extends React.Component {
  public state = { systemNotice: []};

  async componentDidMount(): Promise<void> {
    const req = await request<BaseData<any>>({
      url: '/api/baseinfo/rest/homepage/queryCommonInfo?homePageType=3',
      method: 'get',
    });
    this.setState({ systemNotice: req.data.systemNotice });
  }

  public handleGoToNotice = (id?: number): void => {
    top.egenie.openTab('/egenie-erp-home/announcement', 'announcementList', '公告');
  };

  render() {
    const { systemNotice } = this.state;
    return (
      <div className={styles.notice}>
        <div className={styles.header}>
          <span className={styles.title}>
            公告
          </span>
          <span
            className={styles.more}
            onClick={() => this.handleGoToNotice()}
          >
            更多
            <DoubleRightOutlined/>
          </span>
        </div>
        <div className={styles.noticeContainer}>
          {
            systemNotice.map((item, index) => (
              <div
                className={styles.item}
                key={item.id}
                style={{ borderBottom: index < systemNotice.length - 1 ? '1px dashed #e6e8eb' : 'none' }}
              >
                <div
                  className={styles.content}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
                <div className={styles.date}>
                  {item.time}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}
