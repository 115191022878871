import { Button, Modal, Space, Table } from 'antd';
import { observer } from 'mobx-react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './index.less';
import DialogNoticeStore from './store';
import AuthModal from './authModal';

@observer
export class DialogNotice extends React.Component {
  componentDidMount(): void {
    const { getShopExpireList } = this.store;
    getShopExpireList();
  }

  private store = new DialogNoticeStore();

  // 店铺过期授权页面
  public shopExpire = (): JSX.Element => {
    const { handleShopAgain, onClickServiceSubscriptionRebatesButton, handleAbleShop, toggleShopExpire, shopExpireList } = this.store;
    const columns = [
      {
        title: '店铺名称',
        dataIndex: 'shopName',
        key: 'shopName',
      },
      {
        title: '平台类型',
        dataIndex: 'platformType',
        key: 'platformType',
        width: 90,
      },
      {
        title: '授权到期时间',
        dataIndex: 'expireTime',
        key: 'expireTime',
        width: 160,
      },
      {
        title: '剩余天数',
        dataIndex: 'expireDay',
        key: 'expireDay',
        width: 80,
        render: (_, item) => {
          const isExpire = item.expireDay === '已过期';
          return (
            <span style={{ color: isExpire && 'red' }}>
              {isExpire ? '已过期' : item.expireDay}
            </span>
          );
        },
      },
      {
        title: '操作',
        dataIndex: 'address',
        render: (_, item) => {
          console.log('item......', item);
          return (
            <Space>
              {item.subscriptionServiceUrl ? (
                <a
                  onClick={() => item.subscriptionServiceUrl && window.open(item.subscriptionServiceUrl)}
                >
                  订购服务
                </a>
              ) : undefined}
              <a onClick={() => handleShopAgain(item.shopId, item.platformType)}>
                重新授权
              </a>
              <a onClick={handleAbleShop.bind(this, item.shopId)}>
                禁用店铺
              </a>
            </Space>
          );
        },

      },
    ];

    return (
      <Modal
        centered
        className={styles.shopExpire}
        footer={null}
        getContainer={document.getElementById('dialogNotice')}
        maskClosable={false}
        onCancel={() => {
          toggleShopExpire(false);
        }}
        open
        title="店铺授权过期提醒"
        width={750}
      >
        <div className={styles.shopExpireTableDiv}>
          <div className={styles.tips}>
            <ExclamationCircleOutlined className={styles.tipsExclamationCircleOutlined}/>
            <div>
              <span>
                授权过期后将不能同步商品、订单、售后单等数据，也不能上传发货信息和获取电子面单
              </span>
              <span>
                部分平台授权需要先订购服务，服务订购成功后再点击【重新授权】，
                <a onClick={onClickServiceSubscriptionRebatesButton}>
                  点击查看
                </a>
                服务订购返款相关操作
              </span>
              <span>
                点击【禁用店铺】后，下次登陆将不会再显示该店铺的授权过期提醒
              </span>
            </div>
          </div>
          <Table
            className={styles.shopExpireTable}
            columns={columns}
            dataSource={shopExpireList}
            pagination={false}
            scroll={{ y: '60vh' }}
            size="middle"
          />
        </div>
      </Modal>
    );
  };

  render(): JSX.Element {
    const { isShowShopExpire, zIndex, authModalStore } = this.store;
    return (
      <div
        className={styles.dialogNotice}
        id="dialogNotice"
        style={{ zIndex }}
      >
        <div className={styles.shopExpireWrapper}>
          {isShowShopExpire && this.shopExpire()}
        </div>
        <AuthModal store={authModalStore}/>
      </div>
    );
  }
}
